<template>
  <div class="illegal-create-form common-page">
    <Spin size="large" fix v-if="searchLoading"></Spin>
    <section v-if="alarmData" class="page-content">
      <div class="govern-content">
        <div class="govern-notice">
          <!-- <div class="notice-item">
            <span class="notice-label">治理通报:</span><span class="notice-value">{{alarmData.noticeName}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">通报周期:</span><span class="notice-value">{{alarmData.noticePeriod}}</span>
          </div> -->
          <div class="notice-item">
            <span class="notice-label">治理单:</span><span class="notice-value">{{alarmData.governId}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">治理人:</span><span class="notice-value">{{alarmData.unitName}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">治理车辆:</span><span class="notice-value">{{`${alarmData.vehicleNo}(${alarmData.vehiclePlateColor})`}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">治理对象:</span>
            <span class="notice-value">
              <span class="driver-name">{{driversName.map(item => item.label.split('-')[0]).join(', ')}}</span>
              <span v-if="step == 1" @click="addDriverModal = true" class="btn-span">添加对象</span>
            </span>
          </div>
          <div v-if="step == 2" class="notice-item">
            <span class="notice-label">治理罚单:</span><span class="notice-value">
              <!-- <span class="btn-span">下载罚单</span> -->
              <span class="btn-span" @click="previewTicket">在线预览</span>
              <span v-if="btnPrivilegeCode.governance.includes('ResetTicket')" class="btn-span" @click="checkSetGovern">重开罚单</span>
            </span>
          </div>
        </div>
        <template v-for="item in alarmData.contents">
          <div
            v-if="step == 1 ? (item.alarms.length > 0 || (overSpeedList.length > 0 && item.alarmType === 100)) : item.alarms.filter(cItem => cItem.isChecked).length > 0"
            :key="item.alarmType"
            class="detail-item">
            <header class="item-title">违章类型: {{alarmType.find(alarmItem => alarmItem.value == item.alarmType).label}}</header>
            <div class="govern-notice">
              <div class="notice-item" v-if="item.alarmType === 100 && step == 1">
                <span class="notice-label">违章条数：</span>
                <span class="notice-value">{{step == 1 ? item.alarms.length : item.alarms.filter(cItem => cItem.isChecked).length}}</span>
                <span class="notice-label">违章等级：</span>
                <span class="notice-value">
                  <el-select v-model="overSpeed.alarmLevel" placeholder="请选择"  size="mini" style="width: 150px;">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </span>
                <span class="notice-label">超速比例：</span>
                <span class="notice-value">
                  <el-input size="mini" style="width: 120px;" v-model="overSpeed.ratioMin" @input="changeInput('overSpeed.rationMin')">
                    <template slot="suffix">%</template>
                  </el-input>
                   - 
                  <el-input size="mini" style="width: 120px;" v-model="overSpeed.ratioMax" @input="changeInput('overSpeed.rationMin')">
                    <template slot="suffix">%</template>
                  </el-input>
                </span>
                <span class="notice-label"><Button @click="getFilterData(item.alarmType)" :loading="searchLoading" class="form-btn" type="primary">查询</Button></span>
              </div>  
              <div class="notice-item" v-else>
                <span class="notice-label">违章条数:</span><span class="notice-value">
                  {{step == 1 ? item.alarms.length : item.alarms.filter(cItem => cItem.isChecked).length}}
                </span>
              </div>
            </div>
            <div class="item-table">
              <Table
                v-if="step == 1"
                :columns="[{type: 'selection', width: 60, align: 'center'}, ...alarmTableColmuns.find(aItem => aItem.id == item.alarmType).colmuns]"
                :data="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).data"
                size="small"
                max-height="300"
                @on-select="checkSelectAlarmType = item.alarmType"
                @on-select-all="checkSelectAlarmType = item.alarmType"
                @on-selection-change="alarmTableSelect">
              </Table>
              <Table
                v-if="step == 2"
                :columns="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).colmuns"
                :data="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).data.filter(tItem => tItem.isChecked)"
                size="small"
                max-height="300"
                @on-select="checkSelectAlarmType = item.alarmType"
                @on-select-all="checkSelectAlarmType = item.alarmType"
                @on-selection-change="alarmTableSelect">
              </Table>
            </div>
            <div v-if="step == 1" class="govern-from">
              <Form :model="item.editForm" :label-width="80">
                <FormItem label="治理方式:">
                  <div class="form-content">
                    <CheckboxGroup v-model="item.editForm.punishId" class="govern-checkbox">
                      <Checkbox v-for="pItem in punishType" :label="pItem.value" :key="pItem.value">
                        {{pItem.label}}
                        <!-- <DatePicker v-if="pItem.value == 3" :disabled="!item.editForm.punishId.includes(3)" class="check-input" v-model="item.editForm.punishTime" type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="请选择停班时间" size="small" style="width: 300px"></DatePicker>
                        <InputNumber v-if="pItem.value == 4" :disabled="!item.editForm.punishId.includes(4)" class="check-input" v-model="item.editForm.punishMoney" :min="0" size="small" placeholder="请输入罚款金额" style="width: 200px"></InputNumber> -->
                      </Checkbox>
                    </CheckboxGroup>
                    <div class="input-wrapper">
                      <div v-for="pItem in punishType" :key="pItem.value" class="input-item">
                        <DatePicker v-if="pItem.value == 3" :disabled="!item.editForm.punishId.includes(3)" class="check-input" v-model="item.editForm.punishTime" type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="请选择停班时间" size="small" style="width: 300px"></DatePicker>
                        <InputNumber v-if="pItem.value == 4" :disabled="!item.editForm.punishId.includes(4)" class="check-input" v-model="item.editForm.punishMoney" :min="0" size="small" placeholder="请输入罚款金额" style="width: 200px"></InputNumber>
                      </div>
                    </div>
                  </div>
                  
                </FormItem>
                <FormItem label="治理备注:">
                  <Input v-model="item.editForm.remark" type="textarea" placeholder="" />
                </FormItem>
              </Form>
            </div>
          </div>
        </template>
        
        <div class="detail-item">
          <div class="btn-wrapper">
            <Button v-if="isShowPrevStepBtn" @click="hopStep(step-1)" class="option-btn" type="primary" icon="ios-undo">上一步</Button>
            <Button v-if="step == 1" @click="previewTicket" class="option-btn" type="primary" >预览罚单</Button>
            <Button v-if="step == 1" @click="nextStep" class="option-btn" type="primary" >下一步</Button>
            <Button v-if="step == 2 && btnPrivilegeCode.governance.includes('Govern')" @click="checkConfirm" class="option-btn" type="primary" >确认开据</Button>
            <Button v-if="alarmData && alarmData.status < 6 && btnPrivilegeCode.governance.includes('Revoke')" @click="repeal" class="option-btn" type="warning" >撤销</Button>
          </div>
        </div>
      </div>
    </section>
    <!-- 添加驾驶员对象 -->
    <Modal v-model="addDriverModal" title="添加驾驶员对象" :footer-hide="true" width="960">
      <div v-if="alarmData" class="transfer-wrapper">
        <GovernDriver :governId="alarmData.governId" :unitId="alarmData.unitId" :defaultDrivers="defaultDrivers" @setDriverName="setDriverName"></GovernDriver>
      </div>
    </Modal>
    <!-- 预览罚单 -->
    <Modal v-model="ticketModal" title="预览罚单" :footer-hide="true" class="common-modal" width="80">
      <div v-if="ticketModal" class="ticket-wrapper">
        <IllegalTicketTemplate :alarmData="alarmData" :driversName="driversName" :isFilterAlarmContent="true" title="违章告知书(公司留底)"></IllegalTicketTemplate>
        <Divider :dashed="true"/>
        <IllegalTicketTemplate :alarmData="alarmData" :driversName="driversName" :isFilterAlarmContent="true" title="违章告知书(当事人)"></IllegalTicketTemplate>
      </div>
    </Modal>
    <!-- 播放视频 -->
    <Modal :footer-hide="true" width="772" v-model="videoModal" :title="videoForm.title">
      <video v-if="videoModal" width="740" height="500" autoplay controls>
        <source :src="videoForm.url" type="video/mp4" />
        您的浏览器不支持 video 标签。
      </video>
    </Modal>
    <!-- 是否开单 -->
    <Modal v-model="governModal" title="是否开单" @on-ok="confirmTicket" width="350">
      <div style="display: flex; justify-content: center;">
        <Checkbox v-if="isIncludesOnlineStudy" v-model="isSendStudySys">是否推行的到学习平台</Checkbox>
      </div>
    </Modal>
  </div>
</template>
<script>
import {getTimeStrByType} from '@/assets/js/common.js'
import {btnPrivilegeMixin, privilegeMixin, paramsMixin, alarmTableMixin, userInfoMixin, dangerousDrivingBehavior} from '@/assets/js/mixin.js'
import axios from 'axios'
import IllegalTicketTemplate from '../illegal_ticket_template/index.vue'
import GovernDriver from '@/components/govern-driver/index.vue'
import {mapState, mapGetters, mapMutations} from 'vuex'
import {isValidRange, isEmpty, isAmoreB} from '../../../../../assets/js/common'
export default {
  mixins: [btnPrivilegeMixin, privilegeMixin, paramsMixin, alarmTableMixin, userInfoMixin, dangerousDrivingBehavior],
  props: {
    targetParams: {
      type: Object,
      default: () => {
        return {
          noticeId: '',
          beginTime: '',
          endTime: '',
          governWay: 1,
          vehicle: ''
        }
      }
    },
    isShowPrevStepBtn: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    step: Number
  },
  components: {
    IllegalTicketTemplate,
    GovernDriver
  },
  data () {
    return {
      alarmData: null,
      // 添加驾驶员对象
      addDriverModal: false,
      driversName: [],
      defaultDrivers: [],
      ticketModal: false,
      searchLoading: false,
      governModal: false,
      isSendStudySys: false, // 是否同步到学习平台
      isIncludesOnlineStudy: true, // 处罚方式是否包含学习平台
      checkSelectAlarmType: '', // 当前check框选择报警类型
      overSpeed: {
        alarmLevel: null,
        ratioMin: null,
        ratioMax: null
      },
      options: [
        {
          value: '',
          label: '全部'
        }, {
          value: '1',
          label: '一级'
        }, {
          value: '2',
          label: '二级'
        }, {
          value: '3',
          label: '三级'
        }, {
          value: '4',
          label: '四级'
        }, {
          value: '5',
          label: '五级'
        }
      ]
    }
  },
  created () {
    this.getAlarmDetail()
  },
  computed: {
    ...mapState({
      overSpeedList: 'overSpeedList'
    })
  },
  methods: {
    ...mapMutations({
      setOverSpeedList: 'setOverSpeedList'
    }),
    ...mapGetters({
      getOverSpeedList: 'getOverSpeedList'
    }),
    // 获取治理单详情数据
    async getAlarmDetail () {
      let _params = {
        appKey: this.userInfo.appKey,
        unitId: this.userInfo.unitId,
        noticeId: this.targetParams.noticeId,
        beginTime: this.targetParams.beginTime,
        endTime: this.targetParams.endTime,
        vehicleNo: this.targetParams.vehicle.split('-')[0],
        vehiclePlateColor: this.targetParams.vehicle.split('-')[1]
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/selectInitGovern`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.alarmData = data.data
          let alarmList
          const contentsList = this.alarmData.contents.filter(item => item.alarmType === 100)
          if (isEmpty(contentsList)) {
            alarmList = []
          } else {
            alarmList = contentsList[0].alarms
          }
          this.setOverSpeedList(alarmList)
          this.alarmTableColmuns.forEach(item => {
            this.alarmData.contents.forEach(aItem => {
              // 为每个违章对象添加编辑form表单
              let _form = {
                punishId: [],
                punishTime: '',
                runTime: '',
                stopTime: '',
                punishMoney: 0,
                remark: ''
              }
              if (item.id == aItem.alarmType) {
                // aItem.alarms.forEach(alarmCtnItem => {
                //   // 给每条记录添加check框
                //   this.$set(alarmCtnItem, 'isChecked', false)
                // })
                item.data = aItem.alarms
                
                // 为每个违章对象添加编辑form表单
                if (aItem.governType) {
                  // 如果治理类型数据存在
                  _form = {
                    punishId: aItem.governType.punishIds,
                    punishTime: [aItem.governType.runTime, aItem.governType.stopTime],
                    runTime: aItem.governType.runTime,
                    stopTime: aItem.governType.stopTime,
                    punishMoney: aItem.governType.amount,
                    remark: aItem.governType.remark
                  }
                }
                this.$set(aItem, 'editForm', _form)
              }
            })
          })
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取违章详情失败, 请稍后重试!')
        }
      }
    },
    // 设置驾驶员对象
    setDriverName (driversName) {
      this.driversName = driversName
    },
    hopStep (step) {
      this.$emit('hopStep', step)
    },
    // 表内报警数据选择
    alarmTableSelect(selection) {
      let alarmIdArray = selection.map(item => item.alarmId)
      this.alarmData.contents.forEach(item => {
        if (item.alarmType == this.checkSelectAlarmType) {
          item.alarms.forEach(aItem => {
            aItem['isChecked'] = alarmIdArray.includes(aItem.alarmId)
          })
        }
      })
    },
    // 预览罚单
    previewTicket () {
      // if (this.driversName.length == 0) {
      //   this.$Message.warning('治理对象不能为空!')
      //   return
      // }
      if (!this.checkTicketCondition()) {
        return
      }
      this.ticketModal = true
    },
    // 重开罚单
    checkSetGovern () {
      this.$Modal.confirm({
        title: '重开罚单',
        content: `重开罚单将对现有治理单内容重置,是否继续？`,
        onOk: async () => {
          this.hopStep(this.step - 1)
        },
        onCancel: () => {}
      })
    },
    // 下一步
    nextStep () {
      if (this.driversName.length == 0) {
        this.$Message.warning('治理对象不能为空!')
        return
      }
      if (!this.checkTicketCondition()) {
        return
      }
      this.hopStep(this.step + 1)
    },
    // 开据
    async checkConfirm () {
      if (this.driversName.length == 0) {
        this.$Message.warning('治理对象不能为空!')
        return
      }
      if (!this.checkTicketCondition()) {
        return
      }
      if (this.isIncludesOnlineStudy) {
        this.governModal = true
      } else {
        this.confirmTicket()
      }
    },
    // 确认开据
    async confirmTicket () {
      // 违章信息
      let _goverAlarms = []
      // 治理参数
      let _governTypes = []

      this.alarmData.contents.forEach(item => {
        if (item.alarms.length == 0 || item.alarms.filter(cItem => cItem.isChecked).length == 0) {
          // 该违章类型没有数据
          return
        }
        _governTypes.push({
          alarmType: item.alarmType,
          governId: this.alarmData.governId,
          punishIds: item.editForm.punishId,
          governType: this.targetParams.governWay,
          remark: item.editForm.remark,
          amount: item.editForm.punishMoney,
          runTime: item.editForm.punishTime.length > 0 ? getTimeStrByType(item.editForm.punishTime[0], '0') : '',
          stopTime: item.editForm.punishTime.length > 0 ? getTimeStrByType(item.editForm.punishTime[1], '0') : '',
        })
        item.alarms.forEach(aItem => {
          if(aItem.isChecked) {
            _goverAlarms.push({
              alarmId: aItem.alarmId,
              alarmType: item.alarmType,
              governNo: aItem.governNo,
              governType: aItem.governType,
              id: aItem.id
            })
          }
        })
      })
      
      // 治理对象
      let _personList = []
      this.driversName.forEach(item => {
        _personList.push({
          appKey: this.userInfo.appKey,
          driverId: item.id,
          governId: this.alarmData.governId
        })
      })
      let _params = {
        appKey: this.userInfo.appKey,
        unitId: this.userInfo.unitId,
        governId: this.alarmData.governId,
        // noticeId: this.alarmData.notice ? this.alarmData.notice.noticeId : '',
        beginTime: this.targetParams.beginTime,
        endTime: this.targetParams.endTime,
        vehicleNo: this.targetParams.vehicle.split('-')[0],
        vehiclePlateColor: this.targetParams.vehicle.split('-')[1],
        goverAlarms: _goverAlarms,
        personList: _personList,
        governTypes: _governTypes,
        pushStatus: (this.isSendStudySys && this.isIncludesOnlineStudy) ? 1 : 0
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/addGovern`,
          data: _params
        })
        this.searchLoading = false
        if (data.code == 200) {
          this.$Message.success('开据成功')
          this.$emit('reSetData')
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('开据失败, 请稍后重试!')
        }
      }
    },
    // 撤销
    repeal () {
      this.$Modal.confirm({
        title: '撤销',
        content: '撤销操作会删除治理单及附带罚单内容,是否继续？',
        onOk: async () => {
          this.searchLoading = true
          try {
            let {data} = await axios({
              method: 'get',
              url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/removeGovern/${this.alarmData.governId}`
            })
            this.searchLoading = false
            if (data.code === 200) {
              this.$Message.success('撤销成功')
              this.$emit('reSetData')
            } else {
              this.$Message.warning(data.msg)
            }
          } catch (error) {
            this.searchLoading = false
            if (!this.checkPrivilege(error)) {
              this.$Message.error('撤销失败, 请稍后重试!')
            }
          }
        },
        onCancel: () => {}
      })
    },

    // 是否选择了处罚方式
    checkTicketConditionT () {
      let result = true
      this.isIncludesOnlineStudy = false
      this.alarmData.contents.forEach(item => {
        if (item.alarms.length > 0) {
          if (item.editForm.punishId.length == 0) {
            result = false
            this.$Message.warning('每种违章类型请至少选择一种治理方式!')
          }
          if (!item.alarms.some(item => item.isChecked)) {
            result = false
            this.$Message.warning('每种违章类型请至少选择一条处理记录!')
          }
          if (item.editForm.punishId.includes(1)) {
            // 如果有在线学习的话
            this.isIncludesOnlineStudy = true
          }
          if (item.editForm.punishId.includes(3)) {
            if (item.editForm.punishTime == '' || item.editForm.punishTime[0] == '' || item.editForm.punishTime[1] == '') {
              result = false
              this.$Message.warning('请选择停班日期!')
            }
          }
        }
      })
      return result
    },
    // 判断是否具备开罚单条件
    checkTicketCondition() {
      let result = true
      let hasCheckData = false
      this.isIncludesOnlineStudy = false
      this.alarmData.contents.forEach(item => {
        if (item.alarms.length > 0) {
          if (item.alarms.some(aItem => aItem.isChecked)) {
            hasCheckData = true
            // 有被选中的违章记录
            if (item.editForm.punishId.length == 0) {
              result = false
              this.$Message.warning(`请对${item.alarmName}至少选择一种治理方式!`)
            }
            if (item.editForm.punishId.includes(1)) {
              // 如果有在线学习的话
              this.isIncludesOnlineStudy = true
            }
            if (item.editForm.punishId.includes(3)) {
              if (item.editForm.punishTime == '' || item.editForm.punishTime[0] == '' || item.editForm.punishTime[1] == '') {
                result = false
                this.$Message.warning('请选择停班日期!')
              }
            }
          }
        }
      })
      if (!hasCheckData) {
        this.$Message.warning('请至少选择一条违章记录!')
        result = false
      }
      return result
    },
    getFilterData (alarmType) {
      const alarmList = this.overSpeedList
      const overSpeed = this.overSpeed
      let dataTem
      switch(alarmType) {
        case 100: {
          if (!isEmpty(this.overSpeed.ratioMin) && !isEmpty(this.overSpeed.ratioMax) && isAmoreB(this.overSpeed.ratioMin, this.overSpeed.ratioMax)) {
            this.$Message.warning('请输入合法的超速比例范围')
            this.overSpeed.ratioMin = ''
            this.overSpeed.ratioMax = ''
            return
          }
          if (isEmpty(overSpeed.alarmLevel)) {
            dataTem = alarmList.filter(item => isValidRange(item.overSpeedRate, overSpeed.ratioMin, overSpeed.ratioMax))
          } else {
            dataTem = alarmList.filter(item => item.alarmLevel === parseInt(overSpeed.alarmLevel) && isValidRange(item.overSpeedRate, overSpeed.ratioMin, overSpeed.ratioMax))
          }
          this.alarmTableColmuns.forEach(item => {
            this.alarmData.contents.forEach(aItem => {
              // 为每个违章对象添加编辑form表单
              let _form = {
                punishId: [],
                punishTime: '',
                runTime: '',
                stopTime: '',
                punishMoney: 0,
                remark: ''
              }
              if (item.id == aItem.alarmType) {
                if (aItem.alarmType === 100) {
                  aItem.alarms = dataTem
                }
                item.data = aItem.alarms
                
                // 为每个违章对象添加编辑form表单
                if (aItem.governType) {
                  // 如果治理类型数据存在
                  _form = {
                    punishId: aItem.governType.punishIds,
                    punishTime: [aItem.governType.runTime, aItem.governType.stopTime],
                    runTime: aItem.governType.runTime,
                    stopTime: aItem.governType.stopTime,
                    punishMoney: aItem.governType.amount,
                    remark: aItem.governType.remark
                  }
                }
                this.$set(aItem, 'editForm', _form)
              }
            })
          })
          break
        }
      }
    },
    changeInput (key) {
      const pattern = /^[1-9][0-9]*$/ // 正整数的正则表达式
      if (!pattern.test(this[key])) {
        this[key] = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-detail-page();
.illegal-create-form {
  position: relative;
  height: 100%;
  width: 100%;
  .govern-content {
    .btn-span {
      margin-right: 15px;
    }
    .govern-from {
      .form-content {
        position: relative;
        width: 100%;
        display: flex;
        .govern-checkbox {
          width: 150px;
        }
        .input-wrapper {
          .input-item {
            height: 32px;
          }
          .check-input {
            margin-left: 10px;
          }
        }
      }
      
    }
  }
  .driver-name {
    margin-right: 10px;
  }
}
.transfer-wrapper {
  padding: 0 30px;
}
</style>